import React, { useState } from "react"
import loadable from "@loadable/component"
import { makeStyles } from "@material-ui/core/styles"
import "../../css/typography-muli.css"
const ChatComponent = loadable(() =>
  import("../../components/prod/custom-page")
)

const useStyles = makeStyles({
  circle: {
    position: "absolute",
    height: "385px",
    width: "489px",
    bottom: 0,
    right: "2%",
  },
  dots: {
    position: "absolute",
    left: "10%",
    top: "100px",
  },
})

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  const classes = useStyles()
  // TODO: set
  const origDynamicStyleOptions = {
    hideSendBox: true,
  }
  const [hideSendBox, setHideSendBox] = useState(
    origDynamicStyleOptions.hideSendBox
  )

  function onHandleRestart() {
    setHideSendBox(origDynamicStyleOptions.hideSendBox)
  }

  return (
    <ChatComponent
      name="[regio iT] Bonn - Bürgerportal"
      pageTitle="Convaise - Bonn - Bürgerportal"
      // homepage="#"
      token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzUxNTk1MDUuMjUyOTYxLCJleHAiOjIzMDU4Nzk1MDUuMjUyOTYxLCJib3RJZCI6ImRkeTE1OW44bDNqdnl1Z3UiLCJpc3MiOiJjb252YWlzZSIsImF1ZCI6ImNvbnZhaXNlLWNvdXJpZXIifQ.rGHzq5_QDVCgLW0USGnNc3u7xzaYOKrnRp44jd0Qo-QWTy-Kj_VMyj1HWIht8T8eWDf_5NJNMfkFRlYGs2K04vJnqoDlDyS5dpMjfdMk30daCviLdtHleG7OwIKLuGompMiX4O0rK-q6-_188AqYCgAEjtZu1a_EyXk4CzA_ne6vSCkoNIgJozw3kEZPN5acB337_fefMyLqVsrqF9ECflm5Jbx5_TSbzxKUjttf4lhTYVv2svnYJHyjxzPlgoqNPgeoVJ9Sv6kSbp93Yn7HJKM0q2njqzucT1InMLBqXM2s5RSCtAtqsZTEKIqfHHxol_YvJpZWQ6eC3KBzmNlucK65je_yQKBzK9TMwbBJayAJ0X_1YrkuQ3xVRUfrwNbqWwkj6cuOabWnZrHjF87IBYmeDLUzDjhPP9w--8YFBS84pDbcZvPCm6fCpYh6eyM5JoLjGtBwJ3kCvUCzE2GX_QIkymwlNrV0VeDJYtprLsdWT6LT9pAoEhpPkh3nIC06VBKk8daJnjbXDwH9S8mG5d0yRnRZrYpdWSLloIN6PDq-j2QAbLcwKKmQ73yguN5KVYo1KS5ush2AQh_vMpTtLs-8LLcKlpjEgkmTVgLsQSwAtrXzwNVK6B8R40ywkBaS-olu13mcUxa_FMlzy1vQ3ozLm9NrmqZmMOyNpayKdW0"
      botId="ddy159n8l3jvyugu"
      avatar={false}
      avatarInitials={false}
      logo="https://service.bonn.de/documents/33843/0/BON_Logo_Stadt_blau_00008b-.png"
      domain="https://courier.convaise.com/frontend/v3/directline"
      // domain="http://localhost:8000/v3/directline"
      origDynamicStyleOptions
      onHandleRestart={onHandleRestart}
      styleOptions={{
        primaryFont: "'Noto Sans', 'sans-serif'",
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#134d88",
        botAvatarBackgroundColor: "#fff",
        hideSendBox,
        // primaryFont:
        //   '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      styleCustomizations={{
        "--button-blue": "#134d88",
        "--button-blue-light": "#5a82ac",
        // "--button-red": "rgb(120, 125, 141)",
        // "--button-red-light": "rgba(120, 125, 141, 0.8)",
      }}
      onHideSendBox={value => {
        // console.log("changing hideSendbox")
        setHideSendBox(value)
      }}
      backgroundColor="#F0F4F5"
      customs={[
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          className={classes.circle}
          role="presentation"
          alt=""
          key="customization-circle"
        />,
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          className={classes.dots}
          key="customization-dots"
          role="presentation"
          alt=""
        />,
      ]}
      // css="https://static.convaise.com/webchat/gegenbauer/convaise-assistant-gegenbauer-iframe-min.css"
    />
  )
}

export default Chat
